import { Database } from 'common-ts';
import { FeatureFlags } from '../general/featureFlags';
import { SupabaseClient } from '@supabase/supabase-js';

export { getUserPlan };

export type UserPlan = Exclude<
  Database['public']['Views']['user_plan']['Row']['plan'],
  null
>;

export const FREE_USER_REQUEST_LIMT = 20;
export const FREE_COLLECTION_LIMIT = 2;

/**
 * Retrieves the user's plan from the database.
 *
 * @param client - The Supabase client instance.
 * @returns A promise that resolves to the user's plan, which can be
 * FREE, STANDARD, or ENTERPRISE.
 */
async function getUserPlan(
  client: SupabaseClient,
  workspaceId: string
): Promise<{
  plan: UserPlan;
  marker: boolean;
  workspaceUserType: Database['public']['Enums']['workspace_user_type'];
  workspaceInactive: Database['public']['Tables']['workspace']['Row']['inactive'];
  workspaceUserDisabled: Database['public']['Tables']['workspace_user']['Row']['disabled'];
  licenseType: Database['public']['Tables']['workspace']['Row']['license_type'];
  featureFlags: FeatureFlags;
}> {
  // Fetch the user's plan via the user_plan view.
  const { data: user_plan, error } = await client
    .from('user_plan')
    .select(
      'plan, can_mark_as_test, feat_power_mode, feat_analyze, workspace_user(user_type, disabled, workspace(inactive, license_type, feat_sharepoint, feat_csv))'
    )
    .eq('workspace_id', workspaceId);

  if (error || !user_plan || user_plan.length === 0) {
    return {
      plan: 'FREE',
      marker: false,
      workspaceUserType: 'USER',
      workspaceInactive: null,
      workspaceUserDisabled: null,
      licenseType: 'FREE',
      featureFlags: {
        powerMode: false,
        analyze: false,
        sharepoint: false,
        csv: false,
      },
    };
  }

  return {
    plan: user_plan[0]!.plan,
    marker: user_plan[0]!.can_mark_as_test,
    workspaceUserType: user_plan[0]!.workspace_user[0]?.user_type ?? 'USER',
    workspaceInactive:
      // @ts-ignore supabase typing is wrong here. workspace_user[0].workspace should be an array, but it is a single object.
      user_plan[0]!.workspace_user[0]?.workspace?.inactive ?? null,
    workspaceUserDisabled: user_plan[0]!.workspace_user[0]?.disabled ?? null,
    licenseType:
      // @ts-ignore supabase typing is wrong here. workspace_user[0].workspace should be an array, but it is a single object.
      user_plan[0]!.workspace_user[0]?.workspace?.license_type ?? null,
    featureFlags: {
      powerMode: user_plan[0]!.feat_power_mode,
      analyze: user_plan[0]!.feat_analyze,
      sharepoint:
        // @ts-ignore supabase typing is wrong here. workspace_user[0].workspace should be an array, but it is a single object.
        user_plan[0]!.workspace_user[0]?.workspace?.feat_sharepoint ?? null,
      // @ts-ignore supabase typing is wrong here. workspace_user[0].workspace should be an array, but it is a single object.
      csv: user_plan[0]!.workspace_user[0]?.workspace?.feat_csv ?? null,
    },
  };
}
