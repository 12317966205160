import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Button,
} from '@chakra-ui/react';
import {
  faArrowDownLeft,
  faArrowUpRight,
} from '@fortawesome/pro-solid-svg-icons';
import { useEffect, useMemo, useState } from 'react';

import ChatSnippetResult from './SnippetResult.js';
import DocumentSummaryResult from '../pages/chat/DocumentSummaryResult.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { abstractV1V2 } from './resultsV1V2Conversion.js';
import { useTranslation } from 'react-i18next';
import {
  SnippetResultsV1,
  EnrichedBasicLayoutItem,
} from '../../../common-ts/src/types/chatEndpoint';
import { FileNameId } from 'common-ts';

type QueryResultsProps = {
  snippets: SnippetResultsV1 | EnrichedBasicLayoutItem;
  isPowerMode: boolean;
  focusInlineReference: number;
  clearInlineReference: () => void;
  fileSummaries?: FileNameId[];
  wholeFiles?: FileNameId[];
  isExpanded?: boolean;
};
function QueryResults({
  snippets,
  isPowerMode,
  focusInlineReference,
  clearInlineReference,
  fileSummaries = [],
  wholeFiles = [],
  isExpanded = false,
}: QueryResultsProps) {
  const { t } = useTranslation();
  const [index, setIndex] = useState<number | number[]>(isExpanded ? 0 : -1);
  const open = index !== -1;

  const abstraction = useMemo(() => abstractV1V2(snippets), [snippets]);

  useEffect(() => {
    if (focusInlineReference !== -1) setIndex(0);
  }, [focusInlineReference]);

  return (
    <Accordion defaultIndex={index} allowMultiple className="w-full">
      {/* TODO v2 might make sense displayed another way */}
      {abstraction.length ? (
        <AccordionItem border={'none'}>
          <AccordionButton
            onClick={() => {
              setIndex(index === -1 ? 0 : -1);
            }}
            padding={0}
            className="flex w-full justify-between"
          >
            <div className="text-chakra-gray-500 py-[10px] text-xs font-medium">
              {t('general.foundXSnippets', {
                amount: abstraction.length,
              })}
            </div>
            <Button
              size={'xs'}
              rightIcon={
                <FontAwesomeIcon
                  className="text-xs"
                  icon={open ? faArrowUpRight : faArrowDownLeft}
                />
              }
              className="flex-shrink-0 text-xs"
              colorScheme="maia-accent"
            >
              {open ? t('general.collapse') : t('general.expand')}
            </Button>
          </AccordionButton>
          <AccordionPanel padding={0} className="flex flex-col gap-[10px]">
            <div className="bg-maia-border h-[1px] w-full" />
            {open && (
              <ChatSnippetResult
                result={abstraction}
                focusInlineReference={focusInlineReference}
                clearInlineReference={clearInlineReference}
              />
            )}
          </AccordionPanel>
        </AccordionItem>
      ) : null}
      {isPowerMode && fileSummaries?.length ? (
        <AccordionItem border="none">
          <AccordionButton
            padding={0}
            className="flex w-full justify-between"
            onClick={() => {
              setIndex(index === -1 ? 0 : -1);
            }}
          >
            <div className="text-chakra-gray-500 py-[10px] text-xs font-medium">
              {t('general.usedSummary')}: {fileSummaries.length}
            </div>
            <Button
              size={'xs'}
              rightIcon={
                <FontAwesomeIcon
                  className="text-xs"
                  icon={open ? faArrowUpRight : faArrowDownLeft}
                />
              }
              className="flex-shrink-0 text-xs"
              colorScheme="maia-accent"
            >
              {open ? t('general.collapse') : t('general.expand')}
            </Button>
          </AccordionButton>
          <AccordionPanel padding={0} className="flex flex-col gap-[10px]">
            <div className="bg-maia-gray-200 h-[1px] w-full" />
            <DocumentSummaryResult summariesOrWholeDocs={fileSummaries} />
          </AccordionPanel>
        </AccordionItem>
      ) : null}
      {isPowerMode && wholeFiles?.length ? (
        <AccordionItem border="none">
          <AccordionButton
            padding={0}
            className="flex w-full justify-between"
            onClick={() => {
              setIndex(index === -1 ? 0 : -1);
            }}
          >
            <div className="text-chakra-gray-500 py-[10px] text-xs font-medium">
              {t('general.usedWhole')}: {wholeFiles.length}
            </div>
            <Button
              size={'xs'}
              rightIcon={
                <FontAwesomeIcon
                  className="text-xs"
                  icon={open ? faArrowUpRight : faArrowDownLeft}
                />
              }
              className="flex-shrink-0 text-xs"
              colorScheme="maia-accent"
            >
              {open ? t('general.collapse') : t('general.expand')}
            </Button>
          </AccordionButton>
          <AccordionPanel padding={0} className="flex flex-col gap-[10px]">
            <div className="bg-maia-gray-200 h-[1px] w-full" />
            <DocumentSummaryResult summariesOrWholeDocs={wholeFiles} />
          </AccordionPanel>
        </AccordionItem>
      ) : null}
    </Accordion>
  );
}

export default QueryResults;
